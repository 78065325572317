.defaultButton {
  font-size: 14px;
  border-radius: 12px;
  padding: 6px 16px;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  color: var(--primary);
  text-align: center;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  user-select: none;
  .animate {
    transition: transform ease-in-out 0.3s;
  }
  .rotate {
    transform: rotate(90deg);
  }
  @media (hover: hover) {
    &:hover {
      .animate {
        transform: rotate(90deg);
      }
    }
  }
  &.whiteText {
    color: var(--text-base) !important;
  }
  &.orangeText {
    color: var(--orange-primary) !important;
  }
  &.boldTitle {
    font-weight: 600;
  }
  &.small {
    height: 30px;
    font-size: 11px;
  }
  &.medium {
    height: 36px;
    font-size: 14px;
    padding: 0 24px;
  }
  &.large {
    height: 44px;
    font-size: 14px;
  }
  &.iconButton {
    min-width: 40px;
    width: 40px;
    max-width: 40px;
    height: 36px;
    padding: 0;
    &.large {
      width: 48px;
      height: 44px;
    }
  }
  &.circleButton {
    width: 24px;
    height: 24px;
    min-width: 24px;
    line-height: 1;
    &.medium {
      height: 30px;
      min-height: 30px;
      width: 30px;
    }
  }
  @media (hover: hover) {
    &:hover:enabled {
      color: var(--primary);
    }
  }
  &.successColor {
    color: var(--green-primary);
    @media (hover: hover) {
      &:hover:enabled {
        color: var(--green-dark);
      }
    }
  }
  &.grayColor {
    color: var(--primary);
    background-color: var(--overlay-light);
  }
  &.warnColor {
    color: var(--red-primary);
    @media (hover: hover) {
      &:hover:enabled {
        color: var(--red-dark);
      }
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
}
.outlineStyle {
  border: 1px solid var(--primary);
  @media (hover: hover) {
    &:hover:enabled {
      color: var(--primary);
      border: 1px solid var(--primary-default);
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &.successColor {
    border: 1px solid var(--green-primary);
    color: var(--green-primary);
    @media (hover: hover) {
      &:hover:enabled {
        color: var(--green-dark);
        border: 1px solid var(--green-dark);
      }
    }
  }
  &.warnColor {
    border: 1px solid var(--red-primary);
    color: var(--red-primary);
    @media (hover: hover) {
      &:hover:enabled {
        color: var(--red-dark);
        border: 1px solid var(--red-dark);
      }
    }
  }
  &.blueColor {
    border: 1px solid var(--blue);
    color: var(--blue);
    &:hover:enabled {
      color: var(--blue);
      border: 1px solid var(--blue);
      opacity: 0.8;
    }
  }
}
.primaryStyle {
  border: 1px solid transparent;
  background-color: var(--primary);
  color: #fff;
  @media (hover: hover) {
    &:hover:enabled {
      background-color: var(--primary-default);
      color: #fff;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &.successColor {
    color: var(--text-primary);
    background-color: var(--green-primary);
    @media (hover: hover) {
      &:hover:enabled {
        background-color: var(--green-secondary);
      }
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
  &.warnColor {
    color: #2f3037;
    background-color: var(--red-primary);
    @media (hover: hover) {
      &:hover:enabled {
        color: #2f3037;
        background-color: var(--red-secondary);
      }
    }
  }
}
.secondaryStyle {
  border: 1px solid transparent;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &.primaryColor {
    background-color: var(--button-secondary);
    @media (hover: hover) {
      &:hover:enabled {
        background-color: var(--button-secondary-hover);
      }
    }
  }
  &.successColor {
    color: var(--green-dark);
    background-color: var(--green-button-secondary);
    @media (hover: hover) {
      &:hover:enabled {
        opacity: 0.8;
      }
    }
  }
  &.warnColor {
    color: var(--red-primary);
    background-color: var(--red-button-secondary);
    @media (hover: hover) {
      &:hover:enabled {
        opacity: 0.8;
      }
    }
  }
}
.buttonKitTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
