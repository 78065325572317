@use "layout-attributes" as la;
@use "mixins" as mix;

/// Generates flex data-attribute selectors
@mixin flex-attributes {
	*[data-flex] {
		@include mix.flex-properties;
	}

	*[data-flex="auto"] {
		@include mix.flex-auto-properties;
	}

	*[data-flex="grow"] {
		@include mix.flex-grow-properties;
	}
	
	*[data-flex="initial"] {
		@include mix.flex-initial-properties;
	}

	*[data-flex="none"] {
		@include mix.flex-none-properties;
	}
	
	*[data-flex="nogrow"] {
		@include mix.flex-nogrow-properties;
	}
	
	*[data-flex="noshrink"] {
		@include mix.flex-noshrink-properties;
	}

	*[data-flex-fill] {
		@include mix.flex-fill-properties;
	}

	*:has(>[data-flex],>[data-flex-fill]):not(#{la.all-data-layout-selectors()}) {
		@include mix.flex-row-properties;
	}
}

/// Generates flex data-attribute selectors for media sizes
@mixin flex-attributes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*[data-flex-#{$name}] {
				@include mix.flex-properties;
			}

			*[data-flex-#{$name}="auto"] {
				@include mix.flex-auto-properties;
			}

			*[data-flex-#{$name}="grow"] {
				@include mix.flex-grow-properties;
			}

			*[data-flex-#{$name}="initial"] {
				@include mix.flex-initial-properties;
			}

			*[data-flex-#{$name}="none"] {
				@include mix.flex-none-properties;
			}

			*[data-flex-#{$name}="nogrow"] {
				@include mix.flex-nogrow-properties;
			}

			*[data-flex-#{$name}="noshrink"] {
				@include mix.flex-noshrink-properties;
			}

			*[data-flex-fill-#{$name}] {
				@include mix.flex-fill-properties;
			}

		}

		*:has(>[data-flex-#{$name}],>[data-flex-fill-#{$name}]):not(#{la.all-data-layout-selectors()}) {
			@include mix.flex-row-properties;
		}
	}
}
