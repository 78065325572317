.mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  background: var(--divider);
}
.mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  height: 4px;
  top: 0;
}
.mat-mdc-slider .mdc-slider__thumb .mdc-slider__thumb-knob {
  border: none;
  height: 16px;
  width: 16px;
}
.mat-mdc-slider .mdc-slider__thumb .mat-ripple {
  display: none;
}
