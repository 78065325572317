body.LIGHT,
body.DARK,
body.DARK_BLUE {
  .connectSxTooltipMessage .mdc-tooltip__surface {
    background: #383942;
    box-shadow:
      0 6px 16px rgba(25, 25, 29, 0.02),
      0 16px 32px rgba(25, 25, 29, 0.04);
    border-radius: 4px;
    color: rgba(255, 252, 255, 0.9);
    font-size: 11px;
    line-height: 1.4;
    font-weight: 400;
    padding: 4px 8px;
    word-break: break-word;
    max-width: 268px;
  }
}
body.DARK_BLUE {
  .connectSxTooltipMessage .mdc-tooltip__surface {
    background: var(--overlay-light);
  }
}
