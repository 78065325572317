@use "mixins" as mix;

/// Generates show/hide attribute selectors
@mixin show-hide-attributes {
	*[data-hide] {
		display: none !important;
	}
	@each $breakpoint, $_ in mix.$flex-layout-media-queries {
		*[data-show-#{$breakpoint}] {
			@include mix.flex-layout-media-not($breakpoint) {
				display: none !important;
			}
		}
		*[data-hide-#{$breakpoint}] {
			@include mix.flex-layout-media($breakpoint) {
				display: none !important;
			}
		}
	}
}
