@use "layout-attributes" as la;
@use "mixins" as mix;

/// Generates layout align data attribute selectors
@mixin layout-align-attributes {
	*[data-layout-align] {
		// First value: justify-content
		&[data-layout-align^="start"] {
			justify-content: flex-start;
		}

		&[data-layout-align^="end"] {
			justify-content: flex-end;
		}

		&[data-layout-align^="center"] {
			justify-content: center;
		}

		&[data-layout-align^="space-between"] {
			justify-content: space-between;
		}

		&[data-layout-align^="space-around"] {
			justify-content: space-around;
		}

		&[data-layout-align^="space-evenly"] {
			justify-content: space-evenly;
		}

		// Last value: align-items
		&[data-layout-align$=" start"] {
			align-items: flex-start;
		}

		&[data-layout-align$=" end"] {
			align-items: flex-end;
		}

		&[data-layout-align$=" center"] {
			align-items: center;
		}

		&[data-layout-align$=" baseline"] {
			align-items: baseline;
		}

		&[data-layout-align$=" stretch"] {
			align-items: stretch;
		}
	}

	*[data-layout-align]:not(#{la.all-data-layout-selectors()}) {
		@include mix.flex-row-properties;
	}

}

@mixin layout-align-attributes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*[data-layout-align-#{$name}] {
				// First value: justify-content
				&[data-layout-align-#{$name}^="start"] {
					justify-content: flex-start;
				}

				&[data-layout-align-#{$name}^="end"] {
					justify-content: flex-end;
				}

				&[data-layout-align-#{$name}^="center"] {
					justify-content: center;
				}

				&[data-layout-align-#{$name}^="space-between"] {
					justify-content: space-between;
				}

				&[data-layout-align-#{$name}^="space-around"] {
					justify-content: space-around;
				}

				&[data-layout-align-#{$name}^="space-evenly"] {
					justify-content: space-evenly;
				}

				// Last value: align-items
				&[data-layout-align-#{$name}$=" start"] {
					align-items: flex-start;
				}

				&[data-layout-align-#{$name}$=" end"] {
					align-items: flex-end;
				}

				&[data-layout-align-#{$name}$=" center"] {
					align-items: center;
				}

				&[data-layout-align-#{$name}$=" baseline"] {
					align-items: baseline;
				}

				&[data-layout-align-#{$name}$=" stretch"] {
					align-items: stretch;
				}
			}
		}
	}
}
