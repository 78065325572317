.inputKitContainer {
  background-color: transparent;
  border-radius: 12px;
  padding: 0 12px;
  position: relative;
  max-height: 48px;
  border: 0.5px solid var(--text-caption);
  &.background {
    background-color: var(--surface);
  }
  &.borderTransparent {
    border: 0.5px solid transparent;
  }
  &.deprecated {
    border: 0.5px solid var(--orange-primary);
  }
  &.filterView {
    height: 48px;
    border: 0.5px solid transparent;
    background-color: var(--overlay-dark);
  }
  .inputHint {
    padding: 0 12px;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.4;
    color: var(--text-caption);
    position: absolute;
    bottom: -17px;
    left: 0;
    &.errorHint {
      color: var(--red-primary);
    }
  }
  &.small {
    max-height: 30px;
    .inputFormField {
      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 14px;
      }
      .mdc-text-field--filled .mdc-floating-label {
        font-size: 11px;
      }
      .iconsContainer {
        margin-top: -4px;
      }
      &.valueExist {
        .mdc-text-field--filled .mdc-floating-label {
          visibility: hidden;
        }
      }
      .mat-mdc-form-field-infix {
        padding-top: 2px;
        min-height: 30px;
        font-size: 11px;
      }
    }
  }
  &.required {
    .mat-mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: var(--text-primary);
      }
    }
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mat-mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  &.focused {
    border: 1px solid var(--primary);
  }
  &.disabledInput {
    border-color: var(--divider);
  }
}
.inputFormField {
  width: 100%;
  &.searchInput {
    .mdc-floating-label {
      margin-top: -2px;
    }
  }
  &.valueExist {
    &.searchInput {
      .mdc-floating-label {
        margin-top: 2px;
      }
    }
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--filled {
    background: none;
    padding: 0;
  }
  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--disabled {
    pointer-events: all;
  }
  .mat-mdc-form-field-infix {
    padding-top: 18px;
    padding-bottom: 0;
    min-height: 48px;
    font-size: 14px;
    display: flex;
    letter-spacing: normal;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    color: var(--text-secondary);
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 21px;
  }
  input {
    &[type='time'] {
      &.mdc-text-field__input::-webkit-calendar-picker-indicator {
        display: block;
      }
      &.timeInput {
        &::-webkit-date-and-time-value {
          text-align: left;
        }
        &::-webkit-calendar-picker-indicator {
          right: 28px;
        }
      }
      &::after {
        display: none;
      }
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        padding: 0;
        opacity: 0.2;
        margin: 0;
        font-size: 20px;
        z-index: 1;
        cursor: pointer;
        top: 12px;
        .DARK &,
        .DARK_BLUE & {
          filter: invert(1);
          opacity: 0.4;
        }
      }
    }
  }
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above,
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
    .mdc-floating-label--float-above {
    color: var(--red-primary);
  }
  .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: var(--red-primary);
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-floating-label--float-above {
    color: var(--text-caption);
  }
  .mdc-text-field--filled .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label--float-above {
    font-size: 14px;
    color: var(--text-caption);
    letter-spacing: normal;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 24px;
  }
  .mdc-line-ripple {
    display: none;
  }
  .iconsContainer {
    padding-left: 5px;
    margin-top: -20px;
  }
  &.mat-form-field-disabled {
    input,
    .mat-mdc-floating-label {
      opacity: 0.6;
    }
  }
}
