@use 'mixins/mixin';

.body {
  @include mixin.tablet-lg() {
    .responsiveTable .tr.mat-mdc-footer-row,
    .responsiveTable tr.mat-mdc-row,
    .connectSxTable .tr.mat-mdc-footer-row,
    .connectSxTable tr.mat-mdc-row {
      height: auto;
    }
    .responsiveTable.mat-table,
    .connectSxTable.mat-table,
    .connectSxTable {
      border: 0;
      vertical-align: middle;
      table-layout: auto;
      tr.expanded-table-row-opened td {
        border-radius: 0 !important;
      }
      caption {
        font-size: 1em;
      }
      td.mat-mdc-cell,
      td.mat-mdc-cell:first-of-type,
      td.mobileTableCell {
        display: block;
        font-size: 14px;
        line-height: 25px;
        text-align: right;
        font-weight: 400;
      }
      td.mat-mdc-cell:before,
      td.mobileTableCell::before,
      span.mobileTableCell::before {
        content: attr(data-label);
        float: left;
        text-align: left;
        min-width: fit-content;
        color: var(--text-caption);
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        margin-right: 16px;
      }
      td.mat-mdc-cell:last-child:not(.mat-column-expandedDetail),
      td.mobileTableCell {
        border-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .wrap .connectSxTable.mobileAutoWidthTD {
      .mat-mdc-row .mat-mdc-cell {
        width: auto !important;
      }
    }

    .wrap {
      padding: 52px 0 24px;
    }
    &.LIGHT,
    &.DARK,
    &.DARK_BLUE {
      ::-webkit-scrollbar:horizontal {
        -webkit-appearance: none;
        appearance: none;
        height: 0;
      }
      .snack-error,
      .snack-warning,
      .snack-success {
        margin-left: 16px;
      }
      .expanded-table .tr.mat-footer-row,
      .expanded-table:not(.BOMTable) tr.mat-mdc-row {
        height: auto;
      }
      .responsiveTable.mat-table {
        .mat-mdc-cell {
          height: auto;
          margin: 0;
          padding: 2%;
        }
        .mat-mdc-row {
          border-bottom: 1px solid var(--divider);
          margin-bottom: 16px;
        }
      }
      .responsiveTable.mat-table:not(.reportTable) .mat-mdc-cell:first-child {
        margin-top: 0;
        background: var(--overlay-dark);
      }
      .responsiveTable thead {
        display: none;
      }
      .connectSxTable:not(.defaultWidth) {
        tr {
          th,
          td {
            // Override specific width from component styles
            width: calc(100vw - 32px) !important;
          }
        }
        &.insideModal {
          tr {
            th,
            td {
              // Override specific width from component styles
              width: calc(100vw - 48px) !important;
            }
          }
          .mobileTableExpand {
            padding-left: 0;
          }
        }
        &.mat-table,
        & {
          .mat-mdc-cell,
          td.mobileTableCell {
            height: auto;
            margin: 0;
            // Override global styles
            padding: 8px 0 !important;
          }
        }
        thead {
          display: none;
        }
        tbody tr {
          &:not(.expanded-table-row):nth-child(odd),
          &.expanded-table-row:nth-child(4n + 1) {
            background-color: transparent;
          }
        }
      }
      .listTableContainer {
        &,
        &.tableWithTotals {
          margin-top: 86px;
          height: auto;
          overflow: hidden;
          padding-right: 0;
          &.withoutFilters {
            margin-top: 0;
          }
          &.shortFilters {
            margin-top: 50px;
          }
        }
      }
      .mat-expansion-panel-body {
        padding-bottom: 4px;
        background-color: transparent;
      }
      .mat-horizontal-stepper-header-container {
        justify-content: center;
      }
      .mat-horizontal-content-container {
        padding-right: 8px !important;
      }
      .mat-step-header {
        .mat-step-icon {
          margin-right: 0;
        }
        .mat-step-label {
          display: none;
        }
      }
      .mat-step-header[aria-selected='true'] {
        .mat-step-icon {
          margin-right: 12px;
        }
        .mat-step-label {
          display: block;
        }
      }
      .mat-mdc-row:not(.expanded-table-row-detail) {
        display: revert;
      }
      .modalViewContainer {
        max-height: 100%;
      }
      .connectSxTablePaginationContainer {
        padding-top: 0;
      }
      .connectSxTablePagination {
        display: none;
      }
      .listTitleContainer {
        padding-top: 4px;
        padding-bottom: 14px;
        margin-bottom: 0;
        position: fixed;
        background-color: var(--base);
        z-index: 1;
        top: 48px;
        width: 100vw;
        &.withoutFilters {
          padding: 0;
        }
      }
      .emphasizedRed,
      .emphasizedOrange,
      .emphasizedSuccess,
      .emphasizedPrimary,
      .emphasizedGray,
      .emphasizedBlue,
      .emphasizedSurface {
        &,
        &.large {
          all: unset;
          font-size: 11px;
          line-height: 1.4;
          font-weight: 400;
          border: 1px solid #000;
          padding: 0 6px;
          height: 24px;
          max-height: 24px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: max-content;
          white-space: nowrap;
        }
      }
      .emphasizedSuccess,
      .emphasizedSuccess.large {
        border-color: var(--green-primary);
        color: var(--green-primary);
      }
      .emphasizedPrimary,
      .emphasizedPrimary.large {
        border-color: var(--primary);
        color: var(--primary);
      }
      .emphasizedRed,
      .emphasizedRed.large {
        border-color: var(--red-primary);
        color: var(--red-primary);
      }
      .emphasizedBlue,
      .emphasizedBlue.large {
        border-color: var(--blue);
        color: var(--blue);
      }
      .emphasizedGray,
      .emphasizedGray.large {
        border-color: var(--icon);
        color: var(--icon);
      }
      .emphasizedOrange,
      .emphasizedOrange.large {
        border-color: var(--orange-primary);
        color: var(--orange-primary);
      }
      .emphasizedSurface,
      .emphasizedSurface.large {
        border-color: transparent;
        background-color: var(--surface);
        color: var(--text-secondary);
      }
      .detailPageHeaderContainer {
        margin-bottom: 0;
      }
      .connectSxTable {
        &.tableInForm {
          tr {
            th,
            td {
              // Override global styles
              width: calc(100vw - 112px) !important;
            }
          }
        }
        &.tableWideWidth {
          tr {
            th,
            td {
              // Override global styles
              width: calc(100vw - 120px) !important;
            }
          }
        }
      }
      .form-group-emphasized {
        padding: 24px 48px;
        margin: 0;
        border: none;
        background-color: var(--surface);
        &.resetPaddingContainer {
          padding: 24px 32px;
          .labelNoItems {
            padding-left: 16px;
          }
          .form-group-emphasized-wrap {
            padding: 0 16px;
          }
          .connectSxTable {
            tr {
              th,
              td {
                // Override global styles
                width: calc(100vw - 96px) !important;
              }
            }
          }
        }
      }
      .mobileLastChild {
        margin-bottom: 92px;
      }
      .mobileFirstChild {
        margin-top: 56px;
        &.expandedView {
          margin-top: 94px;
        }
        &.shortView {
          margin-top: 40px;
          &.expandedView {
            margin-top: 78px;
          }
        }
      }
      .actionButtonsContainer {
        padding: 24px;
        background: rgba(var(--surface-rgb), 0.6);
        backdrop-filter: blur(4px);
        border-radius: 16px 16px 0 0;
        position: fixed;
        bottom: 0;
        z-index: 2;
        width: 100vw;
      }
      .progressBarContainer {
        &.fixed {
          position: fixed;
          left: 0;
          top: 45px;
          margin: 0;
          z-index: 5;
        }
      }
      .largeModalWindow {
        .form-group-emphasized {
          padding: 0;
        }
      }
      .confirmModalWindow {
        .titleContainer {
          margin-bottom: 16px;
        }
      }
      .form-group-emphasized-3-col,
      .form-group-emphasized-2-col {
        //TODO     grid-template-columns: repeat(1, 100%);
        max-width: calc(100vw - 112px);
        margin-right: 0;
        &.equalWidth {
          max-width: calc(100% - 8px);
        }
      }
      .connectSxTab {
        &.dashboardTab {
          padding: 4px 0;
        }
      }
    }
    .mobileTableExpand .mat-expansion-panel-content .mat-mdc-row {
      display: flex;
      flex-direction: column;
    }
    .detailFormsContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    @media (orientation: portrait) {
      .cdk-overlay-pane.largeModalWindow {
        width: 90% !important;
        position: absolute !important;
        margin: 0 !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @include mixin.tablet-sm() {
    .requestFieldsContainer,
    .requestReviewItemsContainer,
    .requestItemsContainer {
      height: auto;
    }
  }
  @media (max-width: 1024px) {
    .container {
      width: 100%;
      padding: 0 15px;
    }
    .responsiveTable:not(.mat-table) {
      display: block;
      overflow-x: scroll;
    }
    .reportTable {
      thead {
        display: none;
      }
      tbody {
        display: block;
      }
    }
    .connectSxTable {
      table-layout: fixed;
    }
    .form-group-emphasized-3-col {
      grid-template-columns: repeat(2, 50%);
    }
  }
  @media (max-width: 980px) and (min-width: 767px) {
    .reportTable thead {
      display: none;
    }
    th.mat-header-cell {
      padding: 0 3px;
    }
  }
}
