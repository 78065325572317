.selectKitContainer {
  background-color: transparent;
  border-radius: 12px;
  padding: 0 12px;
  min-height: 48px;
  max-height: 48px;
  border: 0.5px solid var(--text-caption);
  transition: all 0.2s ease-in-out;
  &.borderTransparent {
    border: 0.5px solid transparent;
  }
  &.required {
    .mat-mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        color: var(--text-primary);
      }
    }
  }
  &.filterView {
    background-color: var(--overlay-dark);
    border: 0.5px solid transparent;
    padding-right: 10px;
    .selectFormField {
      .mat-mdc-floating-label mat-label {
        color: var(--text-secondary);
      }
    }
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mat-mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  &.focused {
    border: 1px solid var(--primary);
  }
  &.disabledInput {
    border-color: var(--divider);
  }
}
.selectFormField {
  width: 100%;
  font-size: 14px;
  position: relative;
  line-height: 1;
  color: var(--text-secondary);
}
.selectFormField {
  .mat-mdc-select-value {
    height: 18px;
  }
  .mat-mdc-select-value-text {
    color: var(--text-secondary);
    font-size: 14px;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
  }
  .mat-mdc-floating-label mat-label {
    color: var(--text-caption);
    font-size: 14px;
  }
  input {
    &:disabled {
      color: var(--text-secondary);
    }
    &::placeholder,
    .DARK &::placeholder,
    .DARK_BLUE &::placeholder {
      color: rgba(var(--icon-rgb), 0.8);
    }
  }
  &.overflowHidden {
    .limitMaxWidth {
      max-width: calc(100% - 28px);
    }
  }
  &.mat-form-field-disabled {
    opacity: 0.6;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
    background: none;
  }
  .mat-mdc-form-field-infix {
    padding: 0;
    min-height: auto;
    padding-top: 16px;
  }
  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none;
  }
  .mat-mdc-select-arrow {
    margin-top: 4px;
    width: 24px;
    height: 24px;
    svg {
      display: none;
    }
    &::after {
      font-family: 'icons', Fallback, sans-serif;
      color: var(--icon);
      content: '\e95a';
      font-size: 24px;
    }
  }
}
.selectHint {
  padding: 0 14px;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.4;
  color: var(--text-caption);
  &.errorHint {
    color: var(--red-primary);
  }
}
.colorPaletteOption {
  .mdc-list-item__primary-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
  }
}
