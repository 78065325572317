.DARK,
.DARK_BLUE,
.LIGHT {
  .datepickerFieldToggle {
    button {
      height: auto;
      width: auto;
      padding: 0;
    }
  }
}
