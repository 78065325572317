// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './mixins/mixin' as mixin;
@use './variables' as vars;
@use 'sass:map';

// Typography
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Poppins, sans-serif',
  $body-1: mat.m2-define-typography-level(null, null, null, $letter-spacing: 'normal'),
  $body-2: mat.m2-define-typography-level(null, null, null, $letter-spacing: 'normal'),
  $button: mat.m2-define-typography-level(null, null, null, $letter-spacing: 'normal')
);

@include mat.elevation-classes();
@include mat.app-background();

@function create-custom-theme($primary, $accent, $warn, $background, $foreground) {
  @return (primary: $primary, accent: $accent, warn: $warn, background: $background, foreground: $foreground);
}

$console-primary: (
  50: #7f3791,
  100: #7f3791,
  200: #7f3791,
  300: #7f3791,
  400: #7f3791,
  500: #7f3791,
  600: #7f3791,
  700: #7f3791,
  800: #7f3791,
  900: #7f3791,
  A100: #7f3791,
  A200: #7f3791,
  A400: #7f3791,
  A700: #7f3791,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);

$connectsx-console-primary: mat.m2-define-palette($console-primary);
$connectsx-console-accent: mat.m2-define-palette(mat.$m2-yellow-palette);

// Create the theme object (a Sass map containing all of the palettes).
$connectsx-console-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $connectsx-console-primary,
      accent: $connectsx-console-accent
    ),
    typography: $custom-typography
  )
);

// Dark theme
$dark-console-primary: (
  50: #d08ae5,
  100: #d08ae5,
  200: #d08ae5,
  300: #d08ae5,
  400: #d08ae5,
  500: #d08ae5,
  600: #d08ae5,
  700: #d08ae5,
  800: #d08ae5,
  900: #d08ae5,
  A100: #d08ae5,
  A200: #d08ae5,
  A400: #d08ae5,
  A700: #d08ae5,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);

$dark-background: (
  background: #19191d,
  card: #19191d,
  dialog: #2d2f34,
  disabled-button: rgba(white, 0.12),
  raised-button: map.get(mat.$m2-grey-palette, 700),
  focused-button: rgba(white, 0.12),
  selected-button: map.get(mat.$m2-grey-palette, 900),
  selected-disabled-button: map.get(mat.$m2-grey-palette, 800),
  disabled-button-toggle: #222,
  unselected-chip: map.get(mat.$m2-grey-palette, 700),
  disabled-list-option: #222,
  tooltip: map.get(mat.$m2-grey-palette, 700)
);

$dark-foreground: (
  base: #e4e4e4,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: #ffffff3b,
  disabled-text: var(--text-secondary),
  elevation: #000000,
  hint-text: rgba(white, 0.5),
  secondary-text: map.get($dark-console-primary, 500),
  icon: rgba(255, 252, 255, 0.7),
  icons: rgba(255, 252, 255, 0.7),
  text: rgba(255, 252, 255, 0.7),
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3)
);

$dark-primary: mat.m2-define-palette($dark-console-primary);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$dark-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$dark-theme: create-custom-theme($dark-primary, $dark-accent, $dark-warn, $dark-background, $dark-foreground);

// Dark Blue theme
$dark-blue-console-primary: (
  50: #e14eca,
  100: #e14eca,
  200: #e14eca,
  300: #e14eca,
  400: #e14eca,
  500: #e14eca,
  600: #e14eca,
  700: #e14eca,
  800: #e14eca,
  900: #e14eca,
  A100: #e14eca,
  A200: #e14eca,
  A400: #e14eca,
  A700: #e14eca,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white
  )
);

$dark-blue-background: (
  background: #1e1e2e,
  card: #27293d,
  dialog: #27293d,
  disabled-button: rgba(white, 0.12),
  raised-button: map.get(mat.$m2-grey-palette, 700),
  focused-button: rgba(white, 0.12),
  selected-button: map.get(mat.$m2-grey-palette, 900),
  selected-disabled-button: map.get(mat.$m2-grey-palette, 800),
  disabled-button-toggle: #222,
  unselected-chip: map.get(mat.$m2-grey-palette, 700),
  disabled-list-option: #222,
  tooltip: map.get(mat.$m2-grey-palette, 700)
);

$dark-blue-foreground: (
  base: rgba(254, 250, 255, 0.9),
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba(255, 255, 255, 0.1),
  disabled-text: var(--text-secondary),
  elevation: #000000,
  hint-text: rgba(white, 0.5),
  secondary-text: map.get($dark-console-primary, 500),
  icon: rgba(241, 236, 242, 0.6),
  icons: rgba(241, 236, 242, 0.6),
  text: rgba(241, 236, 242, 0.6),
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3)
);

$dark-blue-primary: mat.m2-define-palette($dark-blue-console-primary);
$dark-blue-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$dark-blue-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$dark-blue-theme: create-custom-theme($dark-blue-primary, $dark-blue-accent, $dark-blue-warn, $dark-blue-background, $dark-blue-foreground);

@include mat.elevation-classes();
@include mat.app-background();
@include mat.menu-theme($connectsx-console-theme);
@include mat.progress-spinner-theme($connectsx-console-theme);
@include mat.select-theme($connectsx-console-theme);
@include mat.table-theme($connectsx-console-theme);
@include mat.progress-bar-theme($connectsx-console-theme);
@include mat.checkbox-theme($connectsx-console-theme);
@include mat.radio-theme($connectsx-console-theme);
@include mat.datepicker-theme($connectsx-console-theme);
@include mat.slider-theme($connectsx-console-theme);
@include mat.bottom-sheet-theme($connectsx-console-theme);

// Here are all material modules we are using
// Uncomment if there are bugs with style of any of these modules
// If there is a color issue, include specific module bellow the class DARK, DARK_BLUE
//@include mat.autocomplete-theme($connectsx-console-theme);
//@include mat.input-theme($connectsx-console-theme);
//@include mat.icon-theme($connectsx-console-theme);
//@include mat.tabs-theme($connectsx-console-theme);
//@include mat.paginator-theme($connectsx-console-theme);
//@include mat.sort-theme($connectsx-console-theme);
//@include mat.chips-theme($connectsx-console-theme);
//@include mat.tooltip-theme($connectsx-console-theme);
//@include mat.expansion-theme($connectsx-console-theme);
//@include mat.stepper-theme($connectsx-console-theme);

.LIGHT {
  @include mixin.spread-map(vars.$light-mode);
}

.DARK {
  @include mixin.spread-map(vars.$dark-mode);
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.datepicker-color($dark-theme);
  @include mat.checkbox-color($dark-theme);
  @include mat.select-color($dark-theme);
  @include mat.radio-color($dark-theme);
  @include mat.bottom-sheet-color($dark-theme);
}

.DARK_BLUE {
  @include mixin.spread-map(vars.$dark-blue-mode);
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.datepicker-color($dark-blue-theme);
  @include mat.checkbox-color($dark-blue-theme);
  @include mat.select-color($dark-blue-theme);
  @include mat.radio-color($dark-blue-theme);
  @include mat.bottom-sheet-color($dark-blue-theme);
}
