.body {
  .full-width {
    width: 100%;
  }
  .full-height {
    height: 100%;
  }
  .verticalDivider {
    height: 24px;
    width: 1px;
    background-color: var(--divider);
  }
  .colorGreen {
    color: var(--green-primary) !important;
  }
  .colorPrimary {
    color: var(--primary) !important;
  }
  .colorViolet {
    color: var(--primary);
  }
  .colorRed {
    color: var(--red-primary) !important;
  }
  .colorOrange {
    color: var(--orange-primary) !important;
  }
  .colorBlue {
    color: var(--blue) !important;
  }
  .color-gray {
    color: var(--text-caption) !important;
  }
  .emphasizedRed,
  .emphasizedOrange,
  .emphasizedSuccess,
  .emphasizedPrimary,
  .emphasizedGray,
  .emphasizedSurface,
  .emphasizedBlue,
  .chipNew,
  .selectItemButton {
    font-weight: 400;
    font-size: 11px;
    line-height: 1.4;
    padding: 0 4px;
    height: 15px;
    border-radius: 16px;
    user-select: none;
    &.large {
      padding: 4px 12px;
      height: 24px;
    }
  }
  .chipNew {
    background: #ff0000;
    color: #fff;
  }
  .emphasizedSurface {
    background: var(--surface);
    color: var(--text-secondary);
  }
  .emphasizedGray {
    background: var(--overlay-light);
    color: var(--text-secondary);
    line-height: 13px;
    border: 1px solid rgba(var(--icon-rgb), 0.4);
  }
  .emphasizedSuccess {
    background: var(--green-chip-background);
    color: var(--green-chip-text);
  }
  .emphasizedRed {
    background: var(--red-chip-background);
    color: var(--red-chip-text);
  }
  .emphasizedBlue {
    background: var(--blue-chip-background);
    color: var(--blue);
  }
  .emphasizedOrange {
    background: var(--yellow-chip-background);
    color: var(--yellow-chip-text);
  }
  .emphasizedPrimary {
    background: var(--violet-chip-background);
    color: var(--primary);
  }
  .selectItemButton {
    padding: 0 8px;
    border-radius: 12px;
    background: var(--violet-chip-background);
    color: var(--primary);
    cursor: pointer;
    white-space: nowrap;
    &.danger {
      background-color: var(--red-primary);
      color: var(--text-base);
    }
  }
  .container {
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    max-width: 1900px;
  }
  .wrap {
    padding: 24px 24px 48px 24px;
  }
  .pointer {
    cursor: pointer;
  }
  .tagsContainer {
    margin-bottom: 4px;
    span {
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .subtitleContainer {
    padding-left: 12px;
  }
  .form-group-emphasized {
    margin-right: 16px;
    margin-bottom: 48px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--divider);
    &.closed {
      margin-bottom: 24px;
    }
    &.last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      &.nullMargin {
        margin-right: 0;
      }
    }
    &.nullMargin {
      margin-right: 0;
    }
  }
  // Section inside the accordion wrapped in the form-group-emphasized class
  .form-group-emphasized-section {
    margin-bottom: 48px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--divider);
  }
  .modalViewContainer {
    width: 100%;
    max-width: 1000px !important;
    margin: 0 auto;
    min-height: 150px;
    overflow-y: auto;
    &.large {
      max-height: calc(100vh - 588px);
    }
  }
  .saving {
    span {
      font-size: 11px;
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  .switcherContainer {
    width: max-content;
    background-color: var(--surface);
    border-radius: 12px;
    &.base {
      background-color: var(--overlay-dark);
    }
  }
  .disablePointer {
    pointer-events: none;
  }
  .relativeContainer {
    position: relative;
  }
  .mat-option-dots-long-strings {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hideCells {
    tbody,
    thead {
      display: none;
    }
  }
  .columnsContainer {
    position: absolute;
    right: 12px;
    top: 18px;
    z-index: 5;
    a {
      font-size: 11px;
      line-height: 1.4;
    }
  }
  .pageTabsContainer {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    width: max-content;
  }
  .defaultButtonText,
  .defaultText {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: var(--text-secondary);
    &.bold {
      font-weight: 600;
    }
  }
  .form-group-emphasized-3-col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  .form-group-emphasized-2-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .rotate-right {
    transform: rotate(90deg);
  }
  .caption {
    font-size: 11px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--text-caption);
    &.defaultColor {
      color: var(--text-secondary);
    }
  }
  .textCapitalize {
    text-transform: capitalize;
  }
  .textAlignCenter {
    text-align: center;
  }
  .requestItemsContainer {
    height: 342px;
    overflow-y: auto;
    border: 1px solid var(--divider);
    border-top: none;
    border-radius: 0 0 8px 8px;
    margin-right: 2px;
  }
  .requestReviewItemsContainer {
    height: 345px;
    overflow-y: auto;
  }
  .requestFieldsContainer {
    overflow: hidden auto;
    height: 384px;
  }
  .customSettingsOption {
    padding-bottom: 16px;
    &:not(.noBorder) {
      border-bottom: 1px dotted var(--divider);
    }
  }
  .customSettingsOptionLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: var(--text-secondary);
  }
  .selectActionItem {
    background-color: var(--overlay-dark);
    padding: 4px 8px 4px 16px;
    height: 44px;
    min-height: 44px;
    border-radius: 8px;
    color: var(--text-secondary);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    cursor: pointer;
    &:hover {
      background-color: var(--base);
    }
  }
  .uppercase {
    text-transform: uppercase;
  }
  .noWrapText {
    white-space: nowrap;
  }
  .zebraTables {
    tr {
      background-color: var(--overlay-dark);
      &:nth-of-type(odd) {
        background-color: var(--surface);
      }
    }
  }
  .list-style-disc {
    list-style: disc;
    padding-left: 16px;
    text-align: left;
  }
}
