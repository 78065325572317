@use 'src/assets/styles/mixins/mixin';

.body.platform-ios,
.body.platform-android {
  .connectSxTable tbody tr:not(.expanded-table-row-detail) {
    border-bottom: 1px solid var(--divider);
  }
  .bottomPadding {
    padding-bottom: 110px;
  }
  &.offline {
    .mobileScreen:before {
      background: var(--orange-primary);
    }
  }
  @media (max-width: 1279px) {
    .loadingContainer {
      top: calc(env(safe-area-inset-top) + 45px);
    }
  }
  @include mixin.tablet-md() {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    .mobileScreen {
      top: env(safe-area-inset-top);
      height: 50px;
    }
    .mobileIconContainer {
      top: calc(env(safe-area-inset-top) + 6px);
    }
    .headerContainer {
      margin-top: env(safe-area-inset-top);
    }
    .actionButtonsContainer {
      padding-bottom: 32px;
    }
    .mobileScreen:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: env(safe-area-inset-top);
      top: calc(0px - env(safe-area-inset-top));
    }
    .submitButtonContainer,
    .changePasswordContainer {
      padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    }
    .backgroundContainer {
      padding-top: calc(env(safe-area-inset-top) + 4px);
      height: calc(env(safe-area-inset-top) + 48px);

      &.expandedView {
        height: calc(env(safe-area-inset-top) + 78px);
      }
    }
    .listTitleContainer {
      top: calc(env(safe-area-inset-top) + 48px);
    }
    .connectSxTabContainer {
      top: 48px;
    }
    .footerContainer {
      padding-bottom: 32px;
    }
    &.scanningBarcode {
      background: #00000000 !important;
      &:before,
      &:after {
        background: #00000000 !important;
      }
      .largeModalWindow .mat-mdc-dialog-container {
        background: #00000000 !important;
      }
      .cdk-overlay-backdrop {
        background: #00000000 !important;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          background: var(--base);
          width: 100%;
          left: 0;
          z-index: -1;
        }
        &:before {
          top: 0;
          height: 170px;
        }
        &:after {
          bottom: 0;
          height: 60px;
        }
      }
      app-root,
      .form-group-emphasized,
      .largeModalWindowButtonsContainer {
        display: none !important;
      }
      .cdk-overlay-backdrop:not(.barcodeScannerContainer) + .cdk-global-overlay-wrapper {
        display: none;
      }
    }
    .cdk-overlay-container .mat-snack-bar-container {
      margin-bottom: calc(env(safe-area-inset-bottom) + 76px);
    }
    .loginMobileScreen {
      display: none;
    }
    .detailPageButtonsContainer {
      margin-bottom: 0;
    }
    .mobileScreen:before {
      background: #19191d;
    }
    .mat-horizontal-content-container {
      height: calc(100vh - 240px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
    &.offline {
      .listTableContainer.tableWithTotals {
        margin-top: env(safe-area-inset-top);
      }
    }
    .auditDetail .detailPageHeaderContainer .mobileFirstChild {
      margin-top: -64px; //TODO strange issue on ios
    }
    @media (prefers-color-scheme: dark) {
      &.LIGHT .loginMobileScreen {
        display: block;
        position: absolute;
        width: 100%;
        height: env(safe-area-inset-top);
        &:before {
          height: 100%;
          top: -100%;
        }
      }
    }
  }
  .cdk-overlay-pane:not(.tooltipModalWindow):not(.confirmModalWindow) .mat-mdc-dialog-container {
    padding-top: env(safe-area-inset-top);
  }
  .cdk-overlay-pane.largeModalWindow,
  .mat-mdc-dialog-container {
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    transform: none;
  }
  .mobileFirstChild.shortView {
    margin-top: 56px;
  }
}
.body.platform-android {
  &.scanningBarcode {
    .cdk-overlay-backdrop:not(.barcodeScannerContainer) + .cdk-global-overlay-wrapper {
      display: block;
    }
    .scanUsingBarcodeButton {
      margin-bottom: calc(env(safe-area-inset-bottom) + 24px);
    }
  }
  .mat-mdc-dialog-container .titleContainer > div {
    overflow: hidden;
  }
}
@include mixin.tablet-md() {
  .body.platform-ios {
    .wrap {
      padding-top: calc(env(safe-area-inset-top) + 50px);
    }
  }
}
