.DARK,
.DARK_BLUE,
.LIGHT {
  .connectSxProgress {
    border-radius: 4px;
    & .mdc-linear-progress__bar-inner {
      border-color: var(--icon);
    }
    & .mdc-linear-progress__buffer-bar {
      background-color: var(--overlay-dark);
    }
    &.progressSuccess .mdc-linear-progress__bar-inner {
      border-color: var(--green-primary);
    }
    &.progressError .mdc-linear-progress__buffer-bar {
      background-color: var(--overlay-dark);
    }
    &.progressError .mdc-linear-progress__bar-inner {
      border-color: var(--red-primary);
    }
  }
}
.LIGHT,
.DARK_BLUE {
  .connectSxProgress {
    &.progressSuccess .mdc-linear-progress__buffer-bar {
      background-color: var(--green-chip-background);
    }
    &.progressError .mdc-linear-progress__buffer-bar {
      background-color: var(--red-chip-background);
    }
  }
}
