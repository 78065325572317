@use "mixins" as mix;

/// Generates layout data attribute selectors
@mixin layout-attributes {
	*[data-layout^="row"] {
		@include mix.flex-row-properties;

		&[data-layout~="reverse"] {
			@include mix.row-reverse-properties;
		}
	}

	*[data-layout^="column"] {
		@include mix.flex-column-properties;

		&[data-layout~="reverse"] {
			@include mix.column-reverse-properties;
		}
	}

	*[data-layout~="wrap"] {
		@include mix.wrap-properties;
	}
}

@mixin layout-attributes-for-media-sizes {
	// attributes for flex-layout media sizes
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*[data-layout-#{$name}^="row"] {
				@include mix.flex-row-properties;

				&[data-layout-#{$name}~="reverse"] {
					@include mix.row-reverse-properties;
				}
			}

			*[data-layout-#{$name}^="column"] {
				@include mix.flex-column-properties;

				&[data-layout-#{$name}~="reverse"] {
					@include mix.column-reverse-properties;
				}
			}

			*[data-layout-#{$name}~="wrap"] {
				@include mix.wrap-properties;
			}
		}
	}
}

@function all-data-layout-selectors() {
	$breakpoints: mix.get-breakpoint-names();
	$list: "[data-layout],";
	@each $name in $breakpoints {
		$list: $list + #{",[data-layout-" + $name + "]"}
	}
	@return $list
}