.radioKit {
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .mat-mdc-radio-button .mdc-radio {
    padding: 0;
  }
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    height: 16px;
    width: 16px;
  }
  .mdc-radio {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    .mat-radio-ripple .mat-ripple-element {
      opacity: 0 !important;
    }
    .mdc-radio__outer-circle {
      border: 1px solid var(--text-caption);
      height: 16px;
      width: 16px;
    }
    .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
      height: 16px;
      width: 16px;
      transform: scale(0.6);
      transition: none;
      top: -2px;
      left: -2px;
    }
    .mdc-radio__background::before {
      display: none;
    }
  }
  .mdc-label {
    padding: 0;
    font-weight: 400;
    color: var(--text-secondary);
    font-size: 13px;
    line-height: 1;
    white-space: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: inherit;
  }
}
.radioKitLabel {
  font-size: 11px;
  color: var(--text-caption);
  line-height: 1.4;
}
.radioKitTooltip {
  display: inline-block;
  margin: 0 0 4px;
}
