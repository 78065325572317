$light-mode: (
  --primary: #7f3791,
  --primary-default: #601f6d,
  --ultra-light-primary: #e8dff0,
  --light-primary: #c8b2d6,
  --text-primary: #2f3037,
  --text-secondary: #4e4e52,
  --text-caption: #777378,
  --text-base: #fff,
  --base: #f5f5f5,
  --surface: #ffffff,
  --surface-rgb: (
    255,
    255,
    255
  ),
  --overflow: #f2f2f2,
  --button-secondary: #e8dff0,
  --button-secondary-hover: #c8b2d6,
  --green-button-secondary: #d6ece4,
  --red-button-secondary: #f6dadd,
  --light-divider: rgba(255, 252, 255, 0.4),
  --divider: rgba(185, 181, 186, 0.7),
  --overlay-light: #f2f2f2,
  --overlay-dark: #f8f8f8,
  --icon: #b9b5ba,
  --icon-rgb: (
    185,
    181,
    186
  ),
  --option: #fff,
  --option-hover: #f5f5f5,
  --orange-primary: #ffaa33,
  --orange-base: #ffc573,
  --orange-overlay: #ffaa33,
  --red-chip-background: #ffc2c9,
  --red-chip-text: #e54042,
  --green-chip-background: #b8f2de,
  --green-chip-text: #63a68e,
  --yellow-chip-background: #ffe6c2,
  --yellow-chip-text: #f28e00,
  --violet-chip-background: #e8dff0,
  --red-primary: #fa6e7c,
  --red-secondary: #ff8f9c,
  --red-dark: #c73444,
  --green-primary: #5ac9a3,
  --green-secondary: #75e0bc,
  --green-dark: #3c8c6f,
  --green-overlay: #5ac9a3,
  --blue: #0cbee5,
  --blue-chip-background: #d1e8ff,
  --blue-chip-text: #1e90ff,
  --white: #fff,
  --gray: #f2f2f2,
  --light-gray: #eee,
  --gray-default: #ccc,
  --black: #2f3037,
  --mat-app-background-color: #f5f5f5,
  --mat-app-text-color: #f1ecf299
);

$dark-mode: (
  --primary: #d08ae5,
  --primary-default: #b466cc,
  --ultra-light-primary: #533a5a,
  --light-primary: #a970ba,
  --text-primary: rgba(254, 248, 255, 0.9),
  --text-secondary: rgba(255, 252, 255, 0.7),
  --text-caption: rgba(255, 252, 255, 0.4),
  --text-base: #000,
  --base: #19191d,
  --surface: #26272d,
  --surface-rgb: (
    38,
    39,
    45
  ),
  --overflow: #010101,
  --button-secondary: #2c2d34,
  --button-secondary-hover: #383942,
  --green-button-secondary: #2c2d34,
  --red-button-secondary: #2c2d34,
  --light-divider: transparent,
  --divider: rgba(125, 120, 125, 0.4),
  --overlay-light: #2c2d34,
  --overlay-dark: #383942,
  --icon: #7d787d,
  --icon-rgb: (
    125,
    120,
    125
  ),
  --option: #383942,
  --option-hover: #42434c,
  --orange-primary: #ffba59,
  --orange-base: #ffba59,
  --orange-overlay: #f5a83d,
  --red-chip-background: rgba(255, 194, 201, 0.2),
  --red-chip-text: #f5848e,
  --green-chip-background: rgba(184, 242, 222, 0.2),
  --green-chip-text: #75e0bc,
  --yellow-chip-background: rgba(255, 230, 194, 0.2),
  --yellow-chip-text: #ffba59,
  --violet-chip-background: rgba(208, 138, 229, 0.2),
  --red-primary: #e5606b,
  --red-secondary: #f5848e,
  --red-dark: #c74049,
  --green-primary: #5ac9a3,
  --green-secondary: #75e0bc,
  --green-dark: #47a683,
  --green-overlay: #47a683,
  --blue: #48d2f0,
  --blue-chip-background: rgb(72, 210, 240, 0.2),
  --blue-chip-text: #48d2f0,
  --white: #2f3037,
  --gray: #2c2d34,
  --light-gray: #111,
  --gray-default: #2d2f34,
  --black: rgba(255, 255, 255, 0.9),
  --mat-app-background-color: #19191d,
  --mat-app-text-color: #fffcffb3
);

$dark-blue-mode: (
  --primary: #e14eca,
  --primary-default: #c02aac,
  --ultra-light-primary: #312141,
  --light-primary: #9d008b,
  --text-primary: #fff,
  --text-secondary: rgba(255, 255, 255, 0.8),
  --text-caption: rgba(255, 255, 255, 0.6),
  --text-base: #000,
  --base: #1e1e2e,
  --surface: #27293d,
  --surface-rgb: (
    39,
    41,
    61
  ),
  --overflow: #212326,
  --button-secondary: #423453,
  --button-secondary-hover: #3d324e,
  --green-button-secondary: #423453,
  --red-button-secondary: #423453,
  --light-divider: transparent,
  --divider: rgba(255, 255, 255, 0.1),
  --overlay-light: #25273a,
  --overlay-dark: #232537,
  --icon: #9a9a9a,
  --icon-rgb: (
    154,
    154,
    154
  ),
  --option: #232537,
  --option-hover: #25273a,
  --orange-primary: #ffba59,
  --orange-base: #ffba59,
  --orange-overlay: #f5a83d,
  --red-chip-background: #4b3f4d,
  --red-chip-text: #f5848e,
  --green-chip-background: #3d4851,
  --green-chip-text: #75e0bc,
  --yellow-chip-background: #4b464c,
  --yellow-chip-text: #ffba59,
  --violet-chip-background: #423453,
  --red-primary: #fd5d93,
  --red-secondary: #f5848e,
  --red-dark: #f94480,
  --green-primary: #00f2c3,
  --green-secondary: #75e0bc,
  --green-dark: #47a683,
  --green-overlay: #47a683,
  --blue: #48d2f0,
  --blue-chip-background: rgb(72, 210, 240, 0.2),
  --blue-chip-text: #48d2f0,
  --white: #2f3037,
  --gray: #373940,
  --light-gray: #383b40,
  --gray-default: #4a4b4d,
  --black: rgba(254, 250, 255, 0.9),
  --mat-app-background-color: #1e1e2e,
  --mat-app-text-color: #f1ecf299
);
