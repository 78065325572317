// Override link styles
a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: var(--blue);
    }
  }
}
img {
  -webkit-user-drag: none;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: auto;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  display: initial;
  &.nonScrollableBlock {
    overflow: hidden;
  }
  .hidden {
    display: none;
  }
  .google-visualization-tooltip {
    padding: 15px;
    p {
      white-space: nowrap;
      font-weight: 700;
      color: #222;
    }
  }
  .mat-elevation-z8 {
    box-shadow: none;
  }
  .mat-standard-chip {
    height: auto;
  }

  .tableSkeleton,
  .similarInventorySkeleton {
    .skeleton-loader.progress {
      display: block;
      margin-bottom: 0;
      background: transparent;
      border: none;
      border-bottom: 1px solid #0000001f;
      border-radius: 0;
      height: 50px;
    }
  }
}
body.LIGHT {
  background-color: var(--base);
  color: var(--text-secondary);
  .connectSxPageTabActive {
    box-shadow:
      rgba(136, 165, 191, 0.48) 6px 2px 16px 0,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    &.base {
      box-shadow: none;
    }
  }
  .tableSkeleton,
  .similarInventorySkeleton {
    .skeleton-loader.progress:before {
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(238, 238, 238, 0.6), rgba(255, 255, 255, 0));
    }
  }
}
body.DARK_BLUE,
body.DARK {
  .infoWindowContentBody {
    color: #222;
  }
  // Skeleton loader
  .skeleton-loader.progress::before {
    background-image: linear-gradient(90deg, #0000, #0003, #0000);
  }
  .cardItem {
    box-shadow: none;
  }
}
.colorPaletteOption {
  .mat-option-text {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    .optionText {
      margin-right: 16px;
    }
  }
}
.progressBarContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  left: 0;
  &.fixed {
    position: fixed;
    left: auto;
    margin-left: -48px;
    z-index: 1;
  }
}
.staticProgressBarContainer {
  height: 10px;
  // Override fxLayoutGap
  margin-bottom: 0 !important;
}
.spinner {
  margin: 5px auto;
}
table a {
  color: var(--primary);
  word-break: break-word;
}
small {
  font-size: 10px;
}
.dashboardNoItems {
  display: block;
  font-size: 11px;
  color: var(--text-caption);
  padding: 0 12px;
}
.iconDelete {
  cursor: pointer;
}
// hide google logo in search location
.pac-container:after {
  display: none;
}
.calculateButtons {
  background-color: var(--base);
  padding: 4px;
  border-radius: 4px;
  &.backgroundTransparent {
    background-color: transparent;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .calculateTotalCount {
    font-size: 11px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--text-secondary);
  }
  .calculateInputContainer {
    max-width: 40px;
    min-width: 5px;
    padding: 0 4px;
  }
  .calculateInput {
    font-size: 11px;
    width: 100%;
    font-weight: 400;
    color: var(--text-secondary);
    &::placeholder,
    .DARK &::placeholder,
    .DARK_BLUE &::placeholder {
      color: rgba(var(--icon-rgb), 0.8);
    }
  }
}
.mat-sort-header-button {
  text-align: left;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
.editCountInput .mat-form-field-infix {
  padding-top: 0;
}
.cdk-overlay-container ~ .pac-container.pac-logo {
  position: fixed !important;
}
.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.google-visualization-tooltip {
  pointer-events: none;
}
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
// Browser autofill styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--overlay-dark) inset;
  -webkit-text-fill-color: var(--text-secondary);
}
.virtualAutocompleteScroll {
  width: 100%;
  min-height: 50px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
}
::-webkit-scrollbar:horizontal {
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 8px;
  background-color: rgba(var(--icon-rgb), 0.8);
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.google-visualization-tooltip {
  border: none !important;
  border-radius: 8px !important;
  padding: 8px !important;
  background-color: var(--surface) !important;
  box-shadow:
    0 8px 18px rgba(0, 0, 0, 0.02),
    0 18px 36px rgba(0, 0, 0, 0.04) !important;
  .tooltipRow {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 400 !important;
    line-height: 15px;
    color: var(--text-caption);
    span {
      color: var(--text-primary);
    }
  }
}
.mat-select-disabled .mat-select-value {
  color: var(--text-secondary);
}
.mat-chip.mat-standard-chip::after {
  background: transparent;
}
// Global styles for all themes
.LIGHT,
.DARK,
.DARK_BLUE {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: none;
    box-shadow: none;
  }
  .mdc-text-field__input:-webkit-autofill:invalid {
    box-shadow: 0 0 0 30px var(--overlay-dark) inset;
  }
  // Material datepicker styles
  .mat-datepicker-content {
    border-radius: 12px;
    box-shadow:
      0 18px 36px rgba(0, 0, 0, 0.08),
      0 8px 18px rgba(0, 0, 0, 0.04);
  }
  .mat-calendar-arrow {
    fill: var(--icon);
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--primary);
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }
  .mat-icon-button.mat-button-disabled.mat-button-disabled {
    opacity: 0.4;
  }
  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    border: none;
    font-family: 'icons', Fallback, sans-serif;
    color: var(--icon);
    font-size: 24px;
    transform: none;
    margin: 0;
    line-height: 40px;
  }
  // Google maps default tooltip styles
  .gm-style .gm-style-iw {
    font-size: 11px;
    color: #4e4e52;
  }
  .mat-sort-header-arrow {
    color: var(--icon);
  }
  .mdc-icon-button__ripple {
    display: none;
  }
  .customBottomSheet {
    background-color: var(--base);
    box-shadow:
      0 8px 18px rgba(25, 25, 29, 0.02),
      0 18px 36px rgba(25, 25, 29, 0.04);
    border-radius: 16px 16px 0 0;
    padding: 12px 16px 30px 16px;
    .mat-bottom-sheet-container {
      box-shadow: none;
      padding: 0;
      background: none;
      &:not(.mat-bottom-sheet-container-medium) {
        min-width: auto;
        width: calc(100vw - 32px);
      }
    }
    .bottomSheetItem {
      background-color: var(--surface);
      padding: 4px 8px;
      height: 44px;
      min-height: 44px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      color: var(--text-secondary);
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      &.activeItem {
        color: var(--primary);
      }
      &.disabledItem {
        opacity: 0.4;
        pointer-events: none;
      }
    }
    .cancelButton {
      font-size: 16px;
      line-height: 1.4;
      font-weight: 400;
      color: var(--primary);
    }
  }
  // Autocomplete option style
  .mat-mdc-option.connectSxAutocompleteOption {
    height: auto;
    min-height: auto;
    border-top: 1px solid var(--divider);
    line-height: 1;
    background-color: var(--option);
    padding: 12px 16px;
    .mdc-list-item__primary-text {
      overflow: hidden;
    }
    .mat-mdc-option-ripple {
      display: none;
    }
    span,
    p {
      line-height: 1.2;
      font-size: 14px;
      color: var(--text-secondary);
      padding: 0;
    }
    small {
      font-size: 11px;
      color: var(--text-caption);
      line-height: 1.4;
    }
    span,
    p,
    small {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:first-child {
      border-top: none;
    }
    @media (hover: hover) {
      &:hover:not(.mdc-list-item--disabled),
      &:focus:not(.mdc-list-item--disabled) {
        background-color: var(--option-hover);
        span,
        p {
          color: var(--primary);
        }
      }
    }
    &.mdc-list-item--disabled {
      opacity: 0.4;
    }
    &.addNewOption {
      padding: 8px 16px;
      border-top: 2px dashed var(--divider);
      .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }
      .addNewLabel {
        color: var(--primary);
      }
    }
    &.full-width-option .mdc-list-item__primary-text {
      width: 100%;
    }
  }
  .mat-mdc-option.is-loading {
    background-color: var(--option);
    &.mdc-list-item--disabled .mdc-list-item__primary-text {
      opacity: 1;
    }
    .mdc-list-item__primary-text {
      display: block;
      width: 100%;
    }
  }
  // Material select panel styles
  .matSelectDisableOptionCentering,
  .inputWithSelectPanel,
  .itemsPerPageSelectPanel {
    margin: 0;
    padding: 0;
    border-radius: 8px;
    box-shadow:
      0 8px 18px rgba(0, 0, 0, 0.02),
      0 18px 36px rgba(0, 0, 0, 0.04);
  }
  .cdk-overlay-pane:has(.inputWithSelectPanel),
  .cdk-overlay-pane:has(.itemsPerPageSelectPanel) {
    width: auto !important;
  }
  .matMenuOption,
  .matSelectDisableOptionCentering .mat-mdc-option,
  .inputWithSelectPanel .mat-mdc-option,
  .itemsPerPageSelectPanel .mat-mdc-option {
    background-color: var(--option);
    color: var(--text-secondary);
    font-size: 14px;
    line-height: 1.2;
    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.mat-option-disabled {
      opacity: 0.4;
    }
    &.matMenuOptionActive {
      color: var(--primary);
      pointer-events: none;
    }
    @media (hover: hover) {
      &:not(.mat-option-disabled):hover {
        color: var(--primary);
        background-color: var(--option-hover);
      }
    }
  }
  .itemsPerPageSelectPanel .mat-mdc-option {
    min-height: 36px;
    padding: 0 16px;
    font-size: 11px;
    line-height: 1.4;
  }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .matMenuOptionActive {
    background-color: var(--option-hover);
  }
  // Password errors block styles
  .passwordErrorsInfo {
    .mat-mdc-card {
      padding: 0 12px;
      background-color: transparent;
      color: var(--text-secondary);
      justify-content: flex-start;
      &:not([class*='mat-mdc-elevation-z']) {
        box-shadow: none;
      }
      .mat-icon {
        display: none;
        &.mat-warn ~ span {
          color: var(--red-secondary);
        }
        &.mat-primary ~ span {
          color: var(--green-secondary);
        }
      }
      .mat-mdc-card-content {
        flex-direction: row;
        column-gap: 8px;
        padding: 0;
        &::before {
          content: 'Characters:';
          font-size: 11px;
          line-height: 1.4;
          font-weight: 400;
          color: var(--text-caption);
        }
      }
      .info-row {
        span {
          font-size: 11px;
          font-weight: 400;
          line-height: 1.4;
        }
      }
    }
  }
  // Material progress bar styles
  .strengthPasswordContainer {
    .mat-mdc-progress-bar {
      border-radius: 8px;
      margin: 0 12px;
      width: auto;
    }
    .mat-mdc-progress-bar.mat-warn .mat-progress-bar-buffer,
    .mat-mdc-progress-bar.mat-accent .mat-progress-bar-buffer {
      background-color: var(--overlay-light);
    }
    .mat-mdc-progress-bar-fill::after {
      background-color: var(--green-secondary);
    }
    .mat-mdc-progress-bar.mat-warn .mat-progress-bar-fill::after {
      background-color: var(--red-primary);
    }
    .mat-mdc-progress-bar.mat-accent .mat-progress-bar-fill::after {
      background-color: var(--orange-primary);
    }
  }
  .mat-progress-bar-buffer {
    background-color: var(--ultra-light-primary);
  }
  .mat-progress-bar-fill::after {
    background-color: var(--primary);
  }
  .lightLoader {
    .mat-progress-bar-buffer {
      background-color: var(--overlay-light);
    }
    .mat-progress-bar-fill {
      &::after {
        background-color: var(--overlay-dark);
      }
    }
  }
  .squarePlusButton,
  .squareMoreButton {
    position: fixed;
    right: 16px;
    bottom: 34px;
    z-index: 1;
    width: 56px;
    height: 56px;
    color: var(--text-base);
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    font-size: 28px;
    font-weight: 400;
    box-shadow:
      0 1.5px 3px rgba(96, 31, 109, 0.11),
      0 3px 6px rgba(96, 31, 109, 0.16);
    // Override fxLayoutGap
    margin-right: 0 !important;
  }
  .squareMoreButton {
    bottom: 106px;
    background-color: var(--orange-base);
    &.single {
      bottom: 34px;
    }
  }
  // Material snackbar styles
  .snack-error,
  .snack-warning,
  .snack-success {
    background-color: #26272d;
    border-left: 4px solid var(--red-chip-text);
    padding: 16px 24px;
    box-shadow: none;
    border-radius: 4px;
    margin: 12px;
    font-size: 14px;
    .mdc-snackbar__surface {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
      background: none;
      min-width: 296px;
    }
    .mdc-snackbar__label {
      all: unset;
    }
    .mat-mdc-simple-snack-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mat-mdc-button .mat-mdc-button-touch-target,
    .mat-mdc-button .mat-mdc-focus-indicator,
    .mat-mdc-button .mat-mdc-button-ripple,
    .mat-mdc-button .mat-mdc-button-persistent-ripple {
      display: none;
    }
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      margin: 0;
      height: 32px;
      min-width: 32px;
      padding-right: 0;
      line-height: 1;
      color: inherit;
      font-size: 28px;
    }
    &.actionButton {
      .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        height: 16px;
        font-size: 11px;
        color: inherit;
      }
    }
  }
  .firstItemActive {
    .mat-mdc-radio-button:first-child {
      // Override fxLayoutGap
      margin-bottom: 40px !important;
    }
  }
  .secondItemActive {
    .mat-mdc-radio-button:nth-child(2) {
      // Override fxLayoutGap
      margin-bottom: 40px !important;
    }
  }
  .snack-warning {
    color: var(--yellow-chip-text);
    background-color: var(--yellow-chip-background);
    border-left: 4px solid var(--yellow-chip-text);
    .mat-simple-snackbar .mat-button {
      color: var(--yellow-chip-text);
    }
  }
  .snack-error {
    color: var(--red-chip-text);
    background-color: var(--red-button-secondary);
    border-left: 4px solid var(--red-chip-text);
    .mat-simple-snackbar .mat-button {
      color: var(--red-chip-text);
    }
  }
  .snack-success {
    color: var(--green-chip-text);
    background-color: var(--green-button-secondary);
    border-left: 4px solid var(--green-chip-text);
    .mat-simple-snackbar .mat-button {
      color: var(--green-chip-text);
    }
  }
  // Hide angular-calendar tooltips
  .cal-tooltip {
    display: none;
  }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    background-color: var(--surface);
    box-shadow: none;
  }
  .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
  .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    @media (hover: hover) {
      background: transparent;
    }
  }
  .mat-expansion-panel-body {
    padding: 0;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--overlay-dark);
  }
  .mobileTableExpand {
    .mat-expansion-panel-body {
      background-color: transparent;
      padding-bottom: 4px;
    }
  }
  .mat-expansion-indicator svg {
    fill: var(--icon);
  }
  .mobileTableExpandHeader {
    pointer-events: none;
    .mat-expansion-indicator {
      pointer-events: all;
      margin-left: 18px;
    }
    &.mat-expanded {
      .mat-expansion-indicator svg {
        fill: var(--primary);
      }
    }
  }
  .datepickerFormField {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
  // For label value (was cropped)
  .mat-form-field-empty.mat-form-field-label,
  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    overflow: visible;
    color: var(--text-caption);
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: var(--text-caption);
  }
  .mat-select-value,
  .mat-option-text {
    overflow: visible;
  }
  .mat-mdc-chip.mat-mdc-standard-chip,
  .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
    color: var(--text-secondary);
    background-color: var(--overlay-dark);
    @media (hover: hover) {
      &:hover {
        background-color: var(--ultra-light-primary);
      }
    }
  }
  .mat-stepper-horizontal {
    background-color: transparent;
    width: 100%;
  }
  .mat-horizontal-stepper-header-container {
    background-color: var(--overlay-dark);
    height: 40px;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 8px 12px;
  }
  .mat-stepper-horizontal-line {
    border-top: 1px solid var(--divider);
    margin: 0 12px;
  }
  .largeModalWindowContent.small {
    .mat-horizontal-content-container {
      height: 356px;
    }
  }
  .mat-horizontal-content-container {
    padding: 0;
    overflow-y: auto;
    height: 386px;
  }
  .mat-horizontal-stepper-header {
    pointer-events: none;
    padding: 0;
    height: 40px;
    .mat-step-icon {
      margin-right: 12px;
      height: 24px;
      width: 24px;
      background-color: var(--ultra-light-primary);
      color: var(--primary);
      font-size: 11px;
      font-weight: 400;
      box-shadow:
        0 0.5px 1px rgba(25, 25, 29, 0.06),
        0 1px 2px rgba(25, 25, 29, 0.12);
    }
  }
  .mat-step-header[aria-selected='true'] {
    background: none;
    .mat-step-icon {
      background-color: var(--primary);
      color: var(--text-base);
      box-shadow: none;
    }
    .mat-step-text-label {
      color: var(--primary);
    }
  }
  .mat-step-header {
    .mat-step-label {
      min-width: auto;
    }
    .mat-step-icon-state-edit {
      background-color: var(--green-primary);
      color: var(--text-base);
      box-shadow: none;
    }
  }
  .mat-step-text-label {
    font-size: 11px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--text-secondary);
  }
  // Navbar icons color
  .activeNavOption,
  .inactiveNavOption {
    .connectSxIcon::before {
      color: var(--primary) !important;
    }
  }
  @media (hover: hover) {
    .navOption:hover {
      .connectSxIcon::before {
        color: var(--primary) !important;
      }
    }
    .viewDetailsButton:hover {
      .connectSxIcon::before {
        color: var(--blue) !important;
      }
    }
  }
  // Styles for tables
  .connectSxTable {
    table-layout: fixed;
    background-color: transparent;
    &.fixedTitles {
      th {
        background-color: var(--base);
        // Override material z-index 100
        z-index: 4 !important;
      }
    }
    tbody tr {
      height: auto;
      &:not(.expanded-table-row-detail) {
        border-bottom: 0.5px solid var(--divider);
      }
      &.previousRow {
        border-bottom: none !important;
      }
      &:not(.expanded-table-row):not(.mat-header-row):nth-child(odd),
      &.expanded-table-row:nth-child(4n + 1) {
        background-color: rgba(var(--surface-rgb), 0.4);
      }
      &:last-child {
        border-bottom: none;
      }
      &.expanded-table-row {
        cursor: pointer;
      }
      &.expanded-table-row-opened {
        border-bottom: none;
        td {
          background-color: var(--surface);
          overflow: visible;
          text-overflow: initial;
          white-space: normal;
          &:first-child {
            border-top-left-radius: 12px;
          }
          &:last-child {
            border-top-right-radius: 12px;
          }
        }
      }
    }
    th,
    th.mat-header-cell:first-of-type,
    th.mat-header-cell:last-of-type {
      font-weight: 600;
      font-size: 11px;
      padding: 16px 8px;
      line-height: 18px;
      color: var(--text-caption);
      border: none;
    }
    td,
    td.mat-cell:first-of-type,
    td.mat-cell:last-of-type {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none;
      font-size: 11px;
      padding: 16px 8px;
      font-weight: 400;
      line-height: 1.4;
      color: var(--text-secondary);
      &.cellWithChips {
        padding: 4px 8px;
      }
      a,
      span:not(.mat-checkbox-inner-container) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
  border-radius: 8px;
  padding: 0;
  background-color: var(--option);
  box-shadow:
    0 8px 18px rgba(0, 0, 0, 0.02),
    0 18px 36px rgba(0, 0, 0, 0.04) !important;
  &.multipleSelectAutocomplete {
    max-height: 100%;
  }
}
.tooltipModalWindow {
  .mat-mdc-dialog-container {
    margin: 0 auto;
    border-radius: 14px;
    background-color: var(--surface);
    box-shadow: none;
    padding: 20px 16px 8px;
    width: 270px;
    box-sizing: content-box;
  }
  .mat-dialog-content {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
}
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden !important;
}
.mat-drawer-container {
  background-color: var(--base);
}
.mat-drawer {
  &.openedSidebar {
    width: 344px;
    padding: 36px 0 32px;
    cursor: default;
    margin: 0;
    border-radius: 0;
    height: 100vh;
    div {
      .profileContainer {
        background-color: var(--overlay-light);
        margin: 0 12px;
        border-radius: 12px;
        padding: 8px 12px 8px 6px;
      }
      a {
        padding-left: 24px;
      }
      .imageContainer {
        padding-left: 24px;
        img {
          min-width: 240px;
        }
      }
    }
  }
}
.notificationMessage {
  a {
    color: var(--primary);
  }
}
.largeModalWindow,
.confirmModalWindow {
  .mat-mdc-dialog-container {
    padding: 24px;
    background-color: var(--surface);
    border-radius: 12px;
    box-shadow: none;
    overflow: hidden;
    border: none;
    min-width: auto;
    .mdc-dialog__surface {
      box-shadow: none;
      border-radius: 0;
    }
  }
  .titleContainer {
    margin-bottom: 20px;
  }
  .largeModalWindowContent {
    min-height: 100px;
    max-height: calc(100vh - 194px);
    padding-right: 8px;
  }
  .largeModalWindowButtonsContainer {
    padding-top: 24px;
    min-height: 68px;
  }
}
.mediumModalWindow {
  .mat-mdc-dialog-container {
    padding: 24px;
    background-color: var(--surface);
    border-radius: 12px;
    box-shadow: none;
    border: none;
    min-width: auto;
    .mdc-dialog__surface {
      box-shadow: none;
      border-radius: 0;
    }
  }
  .titleContainer {
    margin-bottom: 16px;
  }
  .mediumModalWindowContent {
    overflow-y: auto;
    max-height: calc(100vh - 156px);
  }
  .mediumModalWindowButtonsContainer {
    padding-top: 24px;
  }
}
// Hide required field symbol (asterisk)
.mat-mdc-form-field-required-marker {
  visibility: hidden;
}
.listTableContainer {
  height: calc(100vh - 168px);
  overflow-y: auto;
  padding-right: 8px;
  position: relative;
  &.tableWithTotals {
    height: calc(100vh - 188px);
  }
  &.filtersApplied {
    height: calc(100vh - 200px);
    &.tableWithTotals {
      height: calc(100vh - 220px);
    }
  }
}
.googleAutocompleteContainer {
  border-radius: 12px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: 4px 12px 0;
  border: 0.5px solid var(--divider);
  input {
    text-align: left;
  }
  &.invalid {
    border: 0.5px solid var(--red-primary);
    .googleAutocompleteLabel {
      color: var(--red-primary);
    }
  }
  &.required {
    .mat-form-field-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: var(--text-primary);
      }
    }
  }
  &.disabled {
    input,
    .googleAutocompleteLabel {
      opacity: 0.4;
    }
  }
  &.focused {
    border: 0.5px solid var(--primary);
  }
}
.googleAutocompleteLabel {
  color: var(--text-caption);
  font-size: 11px;
  display: block;
  line-height: 1.4;
  font-weight: 400;
  padding-bottom: 2px;
}
.googleAutocompleteInput {
  text-align: left;
  font-size: 14px !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
  color: var(--text-secondary) !important;
  &::placeholder,
  .DARK &::placeholder,
  .DARK_BLUE &::placeholder {
    color: rgba(var(--icon-rgb), 0.8) !important;
  }
}
.detailPageHeaderContainer {
  margin-bottom: 16px;
}
.detailPageContainer {
  padding: 24px;
  background-color: var(--surface);
  border-radius: 12px;
  position: relative;
  height: calc(100vh - 141px);
  &.calendarView {
    height: auto;
  }
  .detailFormsContainer {
    overflow-y: auto;
  }
  .detailAttachmentsContainer {
    overflow-y: auto;
  }
  &.withTabs {
    border-radius: 0 12px 12px 12px;
  }
}
.statusContainer {
  .label {
    font-weight: 600;
    font-size: 11px;
    line-height: 1.4;
    color: var(--text-primary);
  }
}
.labelNoItems {
  font-size: 11px;
  color: var(--text-secondary);
  line-height: 1.4;
  font-weight: 400;
  &.tableView {
    padding-left: 8px;
  }
}
.listTitleContainer {
  margin-bottom: 16px;
}
.contentContainer {
  min-height: calc(100% - 32px);
}
// Tooltip inside table headers
.tooltipKitContainer {
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
}
.infoDataBlockCardsContainer {
  overflow-y: auto;
  height: 100%;
}
.similarInventorySkeleton .skeleton-loader span {
  height: 95px;
}
map-info-window {
  padding-bottom: 8px;
  display: inline-block;
}
.mat-simple-snackbar .mat-button {
  color: var(--text-secondary);
  font-size: 28px;
  padding: 0;
  margin-left: 8px;
  .mat-button-focus-overlay {
    display: none;
  }
}
.createNonAuthRequestContainer .mat-horizontal-content-container {
  height: auto;
  min-height: 100%;
}
//Hide recaptcha from AI search
.grecaptcha-badge {
  visibility: hidden;
}
//end Hide recaptcha from AI search
.minWidthMatMenu .menuContainer .connectSxTab {
  height: auto;
  white-space: normal;
}
// Capitalize calendar week days
.cal-week-view .cal-header,
.cal-month-view .cal-header {
  text-transform: capitalize;
}
input::-webkit-inner-spin-button {
  display: none;
}
//Location search
.pac-container {
  width: 300px !important;
}
.map-container {
  width: 100% !important;
}
