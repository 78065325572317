.connectSxTabContainer {
  background-color: var(--surface);
  border-radius: 8px;
  height: 32px;
  padding: 4px;
  width: max-content;
  user-select: none;
  &.base {
    background-color: var(--overlay-dark);
  }
  &.full-width {
    width: 100%;
  }
}
.connectSxTab {
  text-align: center;
  padding: 4px 16px;
  font-size: 14px;
  outline: none;
  height: 24px;
  border: 1px solid transparent;
  color: var(--text-caption);
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: var(--primary);
    }
  }
  &.connectSxTabActive {
    pointer-events: none;
    border-radius: 8px;
    background-color: var(--ultra-light-primary);
    color: var(--primary);
    border: 1px solid var(--light-primary);
  }
  &.connectSxTabInactive {
    opacity: 0.4;
    pointer-events: none;
  }
}
.connectSxPageTab {
  text-align: center;
  padding: 8px 16px;
  font-size: 14px;
  outline: none;
  margin-right: 2px;
  color: var(--primary);
  background-color: var(--ultra-light-primary);
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid var(--light-primary);
  border-left: 1px solid var(--light-primary);
  border-right: 1px solid var(--light-primary);
  &.base {
    border-bottom: 1px solid var(--divider);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--text-secondary);
    }
  }
  &.connectSxPageTabActive {
    pointer-events: none;
    border-top: 1px solid var(--surface);
    border-left: 1px solid var(--surface);
    border-right: 1px solid var(--surface);
    background-color: var(--surface);
    color: var(--text-secondary);
    &.base {
      background-color: var(--overlay-dark);
      border-color: var(--divider);
      border-bottom: none;
    }
  }
  &.connectSxPageTabInactive {
    opacity: 0.4;
    pointer-events: none;
  }
}
