@use "mixins" as mix;

/// Generates flex align data attribute selectors
@mixin flex-align-attributes {
	*[data-flex-align="start"] {
		@include mix.flex-align-start-properties;
	}
	*[data-flex-align="center"] {
		@include mix.flex-align-center-properties;
	}
	*[data-flex-align="end"] {
		@include mix.flex-align-end-properties;
	}
	*[data-flex-align="baseline"] {
		@include mix.flex-align-baseline-properties;
	}
	*[data-flex-align="stretch"] {
		@include mix.flex-align-stretch-properties;
	}
}

@mixin flex-align-attributes-for-media-sizes {
	@each $name, $_ in mix.$flex-layout-media-queries {
		@include mix.flex-layout-media($name) {
			*[data-flex-align-#{$name}="start"] {
				@include mix.flex-align-start-properties;
			}
			*[data-flex-align-#{$name}="center"] {
				@include mix.flex-align-center-properties;
			}
			*[data-flex-align-#{$name}="end"] {
				@include mix.flex-align-end-properties;
			}
			*[data-flex-align-#{$name}="baseline"] {
				@include mix.flex-align-baseline-properties;
			}
			*[data-flex-align-#{$name}="stretch"] {
				@include mix.flex-align-stretch-properties;
			}
		}
	}
}
