$mobile-width: 767px;
$tablet-sm-width: 980px;
$tablet-md-width: 1100px;
$tablet-lg-width: 1279px;
$tablet-xl-width: 1440px;

@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet-sm {
  @media screen and (max-width: $tablet-sm-width) {
    @content;
  }
}

@mixin tablet-md {
  @media screen and (max-width: $tablet-md-width) {
    @content;
  }
}

@mixin tablet-lg {
  @media screen and (max-width: $tablet-lg-width) {
    @content;
  }
}

@mixin tablet-xl {
  @media screen and (max-width: $tablet-xl-width) {
    @content;
  }
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}
