@use "sass:map";

// Flex

@mixin flex-properties {
	flex: 1 1 0;
}

@mixin flex-auto-properties {
	flex-basis: 100%;
}

@mixin flex-grow-properties {
	flex: 1 1 100%;
}

@mixin flex-initial-properties {
	flex: 0 1 auto;
}

@mixin flex-none-properties {
	flex: 0 0 auto;
}

@mixin flex-nogrow-properties {
	flex: 0 1 auto;
}

@mixin flex-noshrink-properties {
	flex: 1 0 auto;
}

@mixin flex-fill-properties {
	margin: 0;
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%
}

// Layout

@mixin flex-row-properties {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

@mixin flex-column-properties {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

@mixin row-reverse-properties {
	flex-direction: row-reverse;
}

@mixin column-reverse-properties {
	flex-direction: column-reverse;
}

@mixin wrap-properties {
	flex-wrap: wrap;

}

// Flex Align

@mixin flex-align-start-properties {
	align-self: flex-start;
}

@mixin flex-align-center-properties {
	align-self: center;
}

@mixin flex-align-end-properties {
	align-self: flex-end;
}

@mixin flex-align-baseline-properties {
	align-self: baseline;
}

@mixin flex-align-stretch-properties {
	align-self: stretch;
}

// Media Sizes
// flex-layout breakpoints
$xs: 599px;
$sm: 959px;
$md: 1279px;
$lg: 1919px;
$xl: 5000px;

$flex-layout-media-queries: (
		'xs': 'screen and (max-width: #{$xs})',
		'sm': 'screen and (min-width: #{$xs + 1}) and (max-width: #{$sm})',
		'md': 'screen and (min-width: #{$sm + 1}) and (max-width: #{$md})',
		'lg': 'screen and (min-width: #{$md + 1}) and (max-width: #{$lg})',
		'xl': 'screen and (min-width: #{$lg + 1}) and (max-width: #{$xl})',
		'lt-sm': 'screen and (max-width: #{$xs})',
		'lt-md': 'screen and (max-width: #{$sm})',
		'lt-lg': 'screen and (max-width: #{$md})',
		'lt-xl': 'screen and (max-width: #{$lg})',
		'gt-xs': 'screen and (min-width: #{$xs + 1})',
		'gt-sm': 'screen and (min-width: #{$sm + 1})',
		'gt-md': 'screen and (min-width: #{$md + 1})',
		'gt-lg': 'screen and (min-width: #{$lg + 1})',
);

@function get-breakpoint-names() {
	@return map.keys($flex-layout-media-queries)
}

@mixin xs($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'xs')} {
		@content;
	}
}

@mixin sm($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'sm')} {
		@content;
	}
}

@mixin md($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'md')} {
		@content;
	}
}

@mixin lg($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'lg')} {
		@content;
	}
}

@mixin xl($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'xl')} {
		@content;
	}
}

@mixin lt-sm($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'lt-sm')} {
		@content;
	}
}

@mixin lt-md($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'lt-md')} {
		@content;
	}
}

@mixin lt-lg($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'lt-lg')} {
		@content;
	}
}

@mixin lt-xl($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'lt-xl')} {
		@content;
	}
}

@mixin gt-xs($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'gt-xs')} {
		@content;
	}
}

@mixin gt-sm($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'gt-sm')} {
		@content;
	}
}

@mixin gt-md($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'gt-md')} {
		@content;
	}
}

@mixin gt-lg($invert: false) {
	@media #{if($invert, 'not ', '')}#{map.get($flex-layout-media-queries, 'gt-lg')} {
		@content;
	}
}

@mixin flex-layout-media($size) {
	@media #{map.get($flex-layout-media-queries, $size)} {
		@content;
	}
}

@mixin flex-layout-media-not($size) {
	@media not #{map.get($flex-layout-media-queries, $size)} {
		@content;
	}
}

