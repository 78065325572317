body.LIGHT,
body.DARK_BLUE,
body.DARK {
  .mat-mdc-menu-panel,
  .mat-mdc-select-panel {
    background-color: var(--surface);
    border-radius: 12px;
    min-height: min-content;
    box-shadow:
      0 18px 36px rgba(0, 0, 0, 0.08),
      0 8px 18px rgba(0, 0, 0, 0.04);
    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
      button.mat-mdc-menu-item:not(.matMenuOption) {
        padding: 12px 16px;
        border-bottom: 1px solid rgba(var(--icon-rgb), 0.4);
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        color: var(--text-primary);
        background-color: var(--option);
        &:disabled {
          pointer-events: none;
          opacity: 0.4;
        }
        &:not(.singleItem):last-child {
          border-bottom: none;
        }
        &.checkboxOption {
          border-bottom: none;
          height: 32px;
          min-height: 32px;
        }
        @media (hover: hover) {
          &:hover {
            color: var(--primary);
            background-color: var(--option-hover);
          }
        }
        .mat-mdc-menu-submenu-icon {
          color: var(--icon);
          margin-right: -8px;
        }
      }
    }
  }
  .mat-mdc-menu-panel.wideMatMenu {
    max-width: 380px;
  }
  .eventsFilterMenu {
    min-width: 300px;
    padding: 8px;
  }
}
