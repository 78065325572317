/* You can add global styles to this file, and also import other style files */
@use 'mixins/mixin' as mixin;
@use 'reset';
@use 'css-fx-layout-config';
@use 'shared-clases';
@use 'custom-theme';
@use 'font';
@use 'desktop';
@use 'tablet';
@use 'feature/calendar';
@use 'feature/progress';
@use 'feature/tab';
@use 'feature/board';
@use 'feature/button';
@use 'feature/paginator';
@use 'feature/tooltip';
@use 'feature/menu';
@use 'feature/select';
@use 'feature/input';
@use 'feature/datepicker';
@use 'feature/radio';
@use 'feature/slider';
@use 'mobile';
@use 'capacitor';
