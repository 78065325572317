.connectSxTablePaginationContainer {
  padding-top: 16px;
}
.connectSxTablePagination {
  &.mat-mdc-paginator {
    background: none;
  }
  .mat-mdc-paginator-container {
    padding: 0;
    min-height: auto;
    column-gap: 16px;
    max-height: 20px;
  }
  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    display: none;
  }
  .mat-mdc-paginator-range-label {
    margin: 0;
    font-size: 11px;
    color: var(--text-caption);
    letter-spacing: normal;
    line-height: 1;
    font-weight: 400;
    &::before {
      content: 'Results:';
    }
  }
  .mat-mdc-paginator-range-actions {
    order: 1;
    padding-right: 16px;
    border-right: 1px solid var(--divider);
  }
  &.withTooltip {
    .mat-mdc-paginator-range-actions {
      padding-right: 40px;
    }
  }
  .mat-mdc-paginator-page-size {
    order: 2;
    margin-right: 0;
    align-items: center;
    .mat-mdc-paginator-page-size-label {
      font-size: 11px;
      line-height: 1.4;
      font-weight: 400;
      color: var(--text-secondary);
      letter-spacing: normal;
      margin: 0 16px 0 0;
    }
    .mat-mdc-paginator-page-size-select {
      margin: 0;
      width: 56px;
    }
    .mat-mdc-form-field {
      font-size: 11px;
      position: relative;
      font-weight: 400;
      color: var(--text-secondary);
      background-color: var(--surface);
      padding: 3px 0 3px 4px;
      border-radius: 4px;
      line-height: 1.4;
      box-sizing: border-box;
      letter-spacing: normal;
      &.mat-form-field-disabled {
        opacity: 0.4;
      }
      .mat-mdc-text-field-wrapper {
        padding: 0;
      }
      .mat-mdc-form-field-infix {
        display: flex;
        padding: 0;
        position: relative;
        border: none;
        min-height: auto;
        overflow: hidden;
      }
      .mdc-text-field--outlined .mdc-notched-outline {
        display: none;
      }
      .mat-mdc-select {
        line-height: 1;
      }
      .mat-mdc-select-arrow-wrapper {
        height: auto;
      }
      .mat-mdc-select-arrow {
        margin-top: -14px;
        margin-right: 0;
        border: none;
        svg {
          display: none;
        }
        &::after {
          position: absolute;
          top: -2px;
          right: 0;
          font-family: 'icons', Fallback, sans-serif;
          color: var(--icon);
          content: '\e95a';
          font-size: 24px;
        }
      }
    }
  }
}
