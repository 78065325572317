@use '../mixins/mixin';

.DARK,
.DARK_BLUE,
.LIGHT {
  .cal-month-view {
    background-color: transparent;
  }
  .desktopCalendarNavigation,
  .mobileCalendarNavigation {
    button {
      line-height: 40px;
      padding: 0;
      height: 40px;
      width: 40px;
      background: none;
      border: none;
      outline: none;
    }
  }
  mwl-calendar-week-view .cal-week-view {
    .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment,
    .cal-all-day-events,
    .cal-day-column,
    .cal-day-headers,
    .cal-day-headers .cal-header:not(:last-child) {
      border-style: solid;
      border-color: var(--divider);
    }
  }
  mwl-calendar-month-view {
    .cal-month-view .cal-days,
    .cal-month-view .cal-days .cal-cell-row,
    .cal-month-view .cal-day-cell:not(:last-child) {
      border-color: var(--divider);
    }
  }
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: var(--red-primary);
  }
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--overlay-dark);
  }
  .cal-week-view .cal-day-headers span {
    display: block;
    margin-top: 4px;
  }
  .cal-week-view .cal-event,
  .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
    &:has(.calendarEventOrange) {
      background: var(--yellow-chip-background);
      color: var(--yellow-chip-text);
      border: 1px solid var(--yellow-chip-text);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:has(.calendarEventViolet) {
      background: var(--violet-chip-background);
      color: var(--primary);
      border: 1px solid var(--primary);
    }
    &:has(.calendarEventGray) {
      background: var(--overlay-light);
      color: var(--text-secondary);
      border: 1px solid var(--text-secondary);
    }
    &:has(.calendarEventGreen) {
      background: var(--green-chip-background);
      color: var(--green-chip-text);
      border: 1px solid var(--green-chip-text);
    }
    &:has(.calendarEventRed) {
      background: var(--red-chip-background);
      color: var(--red-chip-text);
      border: 1px solid var(--red-chip-text);
    }
    &:has(.calendarEventBlue) {
      background: var(--blue-chip-background);
      color: var(--blue-chip-text);
      border: 1px solid var(--blue-chip-text);
    }
  }
  .viewByContainer {
    margin-left: 36px;
  }
  .cal-month-view .cal-cell-row:hover {
    // Override inline styles
    background-color: transparent !important;
  }
  .cal-month-view .cal-event {
    cursor: pointer;
    &.calendarEventOrange {
      background: var(--yellow-chip-text);
      border: 1px solid var(--yellow-chip-text);
    }
    &.calendarEventViolet {
      background: var(--primary);
      border: 1px solid var(--primary);
    }
    &.calendarEventGray {
      background: var(--icon);
      border: 1px solid var(--icon);
    }
    &.calendarEventGreen {
      background: var(--green-chip-text);
      border: 1px solid var(--green-chip-text);
    }
    &.calendarEventRed {
      background: var(--red-chip-text);
      border: 1px solid var(--red-chip-text);
    }
    &.calendarEventBlue {
      background: var(--blue-chip-text);
      border: 1px solid var(--blue-chip-text);
    }
  }
  .cal-month-view .cal-day-cell.cal-today {
    // Override component styles everywhere
    background-color: var(--ultra-light-primary);
  }
  .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    // Override component styles everywhere
    font-size: 1.2em !important;
  }
  .cal-month-view .cal-day-badge {
    // Override component styles everywhere
    background-color: var(--primary) !important;
  }
  .dayEventsContainer {
    padding: 16px;
    border-bottom: 1px solid var(--divider);
  }
  .dayEventsName {
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
  }
}
.LIGHT {
  .dayEventsContainer {
    border-bottom: 1px solid #e1e1e1;
    border-right-color: #e1e1e1;
  }
}

@include mixin.tablet-lg() {
  .DARK,
  .DARK_BLUE,
  .LIGHT {
    @media (orientation: portrait) {
      mwl-calendar-month-view-header {
        display: none;
      }
    }
    .cal-week-view .cal-time-events .cal-event-container {
      z-index: 0;
    }
  }
}

@include mixin.mobile() {
  .DARK,
  .DARK_BLUE,
  .LIGHT {
    .cal-month-view .cal-cell-top {
      position: relative;
    }
    .cal-month-view .cal-day-badge {
      position: absolute;
      margin: 0 !important;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
    .dayEventsName {
      max-width: 300px;
    }
  }
}
