@use 'css-fx-layout' as fx;
@use 'mixins/mixin' as mixin;
// Documentation https://philmtd.github.io/css-fx-layout/docs/attribute-selectors/layout
// https://github.com/philmtd/css-fx-layout
// If you want to include the responsive API selectors
$includeResponsiveApi: true;

// If you choose to use attribute selectors:
@include fx.attribute-selectors($includeResponsiveApi);
@include fx.gap-attribute-selectors(0, 64, 1, px, $includeResponsiveApi);
@include fx.gap-attribute-selectors-from-list(4 8 16 32 64, px, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors(20, 250, 10, $includeResponsiveApi);
@include fx.flex-size-attribute-selectors-from-list(33 66, $includeResponsiveApi);

@media (min-width: 1024px) {
  @for $i from 20 through 250 {
    @if $i % 10 == 0 {
      [data-flex='#{$i}px'] {
        flex: 1 0 #{$i}px;
        max-width: #{$i}px;
        min-width: #{$i}px;
      }
    }
  }

  @for $y from 0 through 100 {
    @if $y % 5 == 0 {
      [data-flex='#{$y}%'] {
        flex: 1 1 #{$y + '%'};
        max-width: #{$y + '%'};
      }
    }
  }

  @for $u from 10 through 100 {
    @if $u % 10 == 0 {
      [data-flex='#{$u}'] {
        flex: 1 1 #{$u + '%'};
        max-width: #{$u + '%'};
      }
    }
  }

  [data-flex='5'] {
    flex: 1 1 5%;
    max-width: 5%;
  }
  [data-flex='25'] {
    flex: 1 1 25%;
    max-width: 25%;
  }

  [data-flex='400px'] {
    flex: 1 0 400px;
    max-width: 400px;
    min-width: 400px;
  }
  [data-flex='500px'] {
    flex: 1 0 500px;
    max-width: 500px;
    min-width: 500px;
  }
}

*[data-hide='false'] {
  display: block !important;
  &[data-layout] {
    display: flex !important;
  }
}
th[data-hide='false'],
td[data-hide='false'] {
  display: table-cell !important;
}

[data-layout='column'] {
  display: flex;
  flex-direction: column;
}
//[data-layout='row'] {
//  display: flex;
//}
[data-flex='15'] {
  flex: 1 1 15%;
  max-width: 15%;
}
[data-flex='33'] {
  max-width: 33%;
  min-width: 33%;
}
[data-flex='32'] {
  max-width: 32%;
  min-width: 32%;
}
[data-flex='27'] {
  max-width: 33%;
  min-width: 33%;
}
[data-flex='22'] {
  max-width: 22%;
  min-width: 22%;
}
//[data-flex='50'] {
//  flex: 1 1 50%;
//  max-width: 50%;
//}
[data-flex='56px'] {
  flex: 1 1 56px;
  max-width: 56px;
  min-width: 56px;
}
[data-flex='85'] {
  flex: 1 1 85%;
  max-width: 85%;
}
//Order
[data-order-xs='1'] {
  order: 1;
}
[data-order-xs='2'] {
  order: 2;
}
//end order
[data-flex-fx] {
  display: flex;
}
[data-flex-fx='none'] {
  flex: none;
}
[data-flex='332px'] {
  width: 332px;
}
[data-flex='170px'] {
  width: 170px;
}
[data-layout-gap='150px'] {
  display: flex;
  gap: 150px;
}

@include mixin.tablet-lg() {
  *[data-layout-align-lt-lg][data-layout-align-lt-lg^='space-between'] {
    display: flex;
  }
  *[data-layout-gap-lt-lg='0'] {
    gap: 0;
  }
  [data-flex-lt-lg='100px'] {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  [data-flex]:not([data-flex-lt-lg]):not([data-flex-sm]):not([data-flex-lt-md]) {
    max-width: none;
    flex: 1 1 auto;
  }
  [data-layout-gap-lt-lg='24px'] {
    gap: 24px;
  }
  [data-flex-lt-lg='33'] {
    flex: 1 1 33%;
    max-width: 33%;
  }
  [data-flex-lt-lg='100'] {
    max-width: 100%;
  }
  [data-flex-lt-lg='50'] {
    max-width: 50%;
  }
  [data-layout-align='start start'] {
    flex-direction: row;
    max-width: 100%;
  }
  [data-show-lt-lg] {
    display: flex !important;
  }
  [data-flex-lt-lg='344px'] {
    max-width: 344px;
  }
  [data-hide-lt-lg='false'] {
    display: block !important;
  }
  [data-show-lt-md] {
    display: flex !important;
  }
  [data-flex-lt-md='25'] {
    flex: 1 1 25%;
    max-width: 25%;
  }
  [data-flex-lt-lg='80'] {
    flex: 1 1 80%;
    max-width: 80%;
  }
  [data-flex-lt-lg='25'] {
    flex: 1 1 25%;
    max-width: 25%;
  }
  [data-flex-sm='26'] {
    flex: 1 1 26%;
    max-width: 26%;
  }
  [data-flex-sm='65'] {
    flex: 1 1 65%;
    max-width: 65%;
  }
  [data-flex='332px'] {
    width: auto;
  }
  [data-flex='170px'] {
    width: auto;
    flex: 1 0 100%;
  }
  *[data-flex-lt-lg='30'] {
    max-width: 30%;
  }
  *[data-flex-lt-lg='35'] {
    max-width: 35%;
  }
  *[data-flex-lt-lg='74'] {
    max-width: 74%;
  }
  *[data-flex-lt-md='20'] {
    max-width: 20%;
  }
}

@media (min-width: 960px) and (max-width: 1280px) {
  [data-flex-lt-lg='172px'] {
    flex: 1 1 172px;
    max-width: 172px;
    min-width: 172px;
  }
  [data-flex-lt-lg='344px'] {
    flex: 1 1 344px;
    max-width: 344px;
    min-width: 344px;
  }
}
@media (min-width: 560px) and (max-width: 960px) {
  *[data-flex-lt-md='50'] {
    flex: 0 1 50%;
    max-width: 50% !important;
  }
  [data-flex-lt-md='250px'] {
    width: 250px;
    max-width: 250px !important;
  }
  *[data-flex-sm='30'] {
    flex: 1 1 30%;
  }
}
@include mixin.mobile() {
  [data-flex-xs='100'] {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
  [data-layout-align-xs='start stretch'] {
    place-content: stretch flex-start !important;
  }
  *[data-show-xs] {
    display: block !important;
  }
  *[data-show-xs='false'] {
    display: none !important;
  }
  *[data-layout-gap-xs='0'] {
    gap: 0;
  }
  *[data-layout-gap-xs='8px'] {
    gap: 8px;
  }
}
