.boardContainer {
  min-height: 100%;
  height: auto;
  .noItems {
    padding-left: 4px;
  }
}
.boardSection {
  height: auto;
  border-radius: 8px;
  border: 1px solid var(--divider);
  width: calc(20% - 4px);
  &:nth-child(even) {
    background-color: var(--surface);
  }
  &:nth-child(odd) {
    background-color: var(--overlay-dark);
  }
}
.boardSectionTitle {
  position: sticky;
  left: 0;
  border-radius: 8px 8px 0 0;
  top: 0;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  padding: 8px;
  color: var(--text-secondary);
  border-bottom: 1px solid var(--divider);
  &.green {
    background-color: var(--green-chip-background);
  }
  &.yellow {
    background-color: var(--yellow-chip-background);
  }
  &.purple {
    background-color: var(--ultra-light-primary);
  }
  &.gray {
    background-color: var(--overlay-light);
  }
  &.blue {
    background-color: var(--blue-chip-background);
  }
  &.red {
    background-color: var(--red-chip-background);
  }
}
.boardSectionContent {
  padding: 0 4px 4px 4px;
}
.boardSectionItem {
  font-size: 11px;
  padding: 4px 8px;
  line-height: 1.4;
  border-radius: 8px;
  border: 1px solid var(--divider);
  color: var(--text-secondary);
  cursor: pointer;
  &.WHITE {
    background-color: var(--surface);
  }
  &.GRAY {
    background-color: var(--overlay-dark);
  }
  a,
  .linksList {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .caption {
    white-space: nowrap;
  }
  .bold {
    font-weight: 600;
  }
  .notificationsContainer {
    position: relative;
    .badge {
      background-color: var(--primary);
      color: var(--text-base);
      width: 16px;
      height: 16px;
      right: 2px;
      top: 2px;
      font-size: 10px;
      border-radius: 100%;
      position: absolute;
      &.small {
        font-size: 8px;
      }
    }
  }
}
